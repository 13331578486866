<template>
  <v-container>
    <v-app-bar
      class="hangover-gradient"
      elevation="2"
      color="purple"
      height="80"
      floating
    >
      <v-app-bar-title class="hover">
        <template #text>
          <IconText></IconText>
        </template>
      </v-app-bar-title>
      <v-app-bar-nav-icon
        variant="text"
        @click.stop="onDrawerClick"
      ></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" location="right" temporary>
      <v-list>
        <v-list-item
          v-for="item in items"
          :key="item.value"
          :title="item.title"
          :to="{ hash: item.value }"
        >
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import IconText from "./common/IconText.vue";

export default defineComponent({
  name: "HeaderComponent",

  components: { IconText },

  data: () => ({
    drawer: false,
    items: [
      {
        title: "Galerie",
        value: "#gallery",
      },
      {
        title: "Shishas",
        value: "#shishas",
      },
      {
        title: "Getränke",
        value: "#drinks",
      },
      {
        title: "Öffnungszeiten",
        value: "#contactNlocation",
      },
      {
        title: "Kontakt & Anfahrt",
        value: "#contactNlocation",
      },
    ],
  }),

  methods: {
    onDrawerClick() {
      this.drawer = !this.drawer;
    },
    onDrawerItemClick(event: any) {
      this.$emit("item:clicked", event);
      this.drawer = false;
    },
  },
});
</script>

<style scoped>
.hangover-gradient {
  background: rgb(55, 2, 133);
  background: linear-gradient(
    40deg,
    rgba(55, 2, 133, 1) 18%,
    rgba(14, 26, 115, 1) 44%,
    rgba(18, 24, 64, 1) 77%,
    rgba(6, 6, 6, 1) 100%
  );
}

.v-toolbar__content > .v-btn:last-child {
  margin-inline-start: 10px;
}

.hover:hover {
  cursor: pointer;
}

.v-container {
  padding: 0;
}
</style>
