import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'

const routes = [
    { path: '/', component: App }
]
  
export const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
          return {
            el: to.hash,
            top: 150,
            behavior: 'smooth'
          }
        }
      }
})