<template>
  <v-app id="top">
    <HeaderComponent />
    <BodyComponent />
    <FooterComponent />
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import HeaderComponent from "./components/HeaderComponent.vue";
import BodyComponent from "./components/BodyComponent.vue";
import FooterComponent from "./components/FooterComponent.vue";

export default defineComponent({
  name: "App",
  components: { HeaderComponent, BodyComponent, FooterComponent },
});
</script>

<style></style>
